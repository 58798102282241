import images from 'images'
import { useCallback, useEffect, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'
import { Loader } from 'components'
import cards from './cards'
import head from 'helpers/head'
import mdHandler from 'helpers/mdHandler'
import { Link } from 'react-router-dom'

export default function Main() {
  useEffect(() => {
    head({ title: 'Documentation | Headless SPAS' })
  }, [])

  return (
    <div className={s.main}>
      <BasicFlow />
      <Docs />
    </div>
  )
}

const BasicFlow = () => (
  <div className={s.section1}>
    <div className={s.left}>
      <img src={images.docsImage} alt='' />
    </div>

    <div className={s.right}>
      <div className={s.title}>Basic flow for first time usage</div>

      <div className={s.lists}>
        <div>{ListRenderer(cards.left)}</div>
        <div>{ListRenderer(cards.right)}</div>
      </div>
    </div>
  </div>
)

var docsList = []

const Docs = () => {
  const [loading, setLoading] = useState(true)
  const [selectedDocs, setSelectedDocs] = useState()

  const selectDocs = useCallback(async path => {
    var autoScroll = true

    path = path?.replace(/\/$/, '')

    const paths = docsList.map(({ path }) => '/docs' + path)

    if (!paths.includes(path)) {
      path = '/docs/installation'
      autoScroll = false
    }

    const md = await api.docs.content({}, { path: path })

    const { html } = mdHandler({ md, titleSuffix: ' | Docs | Headless SPAS' })

    document.querySelector('#content').innerHTML = html
    window.Prism?.highlightAll()

    // if (window.location.pathname !== '/docs/installation')

    if (autoScroll)
      window.scroll(
        window.scrollX,
        document.querySelector('#content').offsetTop - document.querySelector('#nav').getBoundingClientRect().height
      )

    setSelectedDocs(path)
  }, [])

  const fetchDocsIndex = useCallback(async () => {
    try {
      const docsAPI = await api.docs.list()

      docsList = docsAPI
      setLoading(false)

      selectDocs(window.location.pathname.replace(/\/$/, ''))
    } catch (error) {
      console.error(error)
      return
    }
  }, [selectDocs])

  useEffect(() => {
    fetchDocsIndex()
  }, [fetchDocsIndex])

  return loading ? (
    <Loader />
  ) : (
    <div id='top' className={s.section2}>
      <div className={s.sidebar}>
        <div className={s.heading}>Documentation</div>

        <div className={s.links}>
          <ul>
            {docsList.map(({ title, path }, i) => {
              path = '/docs' + path
              return (
                <li key={i}>
                  <Link to={path} onClick={() => selectDocs(path)}>
                    <div className={path === selectedDocs ? s.active : ''}>{title}</div>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <div id='content' className={s.content + ' markdown'} />
    </div>
  )
}

const ListRenderer = list =>
  list.map(({ icon, title, info }, i) => (
    <div key={i} className={s.card}>
      <div className={s.icon}>
        <span className='material-icons-round'>{icon}</span>
      </div>

      <div className={s.title}>{title}</div>

      <div className={s.content}>{info}</div>
    </div>
  ))
