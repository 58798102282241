import Home from './home'
import Docs from './docs'
import Pricing from './pricing'
import Support from './support'

import Blogs from './blogs'
import Blogs_blog from './blogs/blog'

import * as Layout from 'layouts'
import { CheckSSR } from 'components'

const main = [
  {
    path: '/home',
    Component: Home,
    Super: Layout.Home
  },
  {
    path: '/docs/*',
    Component: Docs,
    Super: Layout.Home
  },
  {
    path: '/pricing',
    Component: Pricing,
    Super: Layout.Home
  },
  {
    path: '/blogs',
    Component: Blogs,
    Super: Layout.Home
  },
  {
    path: '/blog/*',
    Component: Blogs_blog,
    Super: Layout.Home
  },
  {
    path: '/support',
    Component: Support,
    Super: Layout.Home
  },
  {
    path: '/check-ssr',
    Component: () => (
      <div style={{ marginTop: '7rem' }}>
        <CheckSSR />
      </div>
    ),
    Super: Layout.Home
  }
]

export default main
