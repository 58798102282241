import { marked } from 'marked'
import head from './head'

export default function Main({ md = '', titleSuffix = '', img = '' }) {
  const _meta = /---((.|\n)*?)---/.exec(md)[1]

  const meta = JSON.parse(_meta)

  const content = md.slice(_meta.length + 7)

  const html = marked.parse(content)

  head({
    title: meta.title ? meta.title + titleSuffix : undefined,
    description: meta.description,
    image: img || meta.image
  })

  return { meta, content, html }
}
