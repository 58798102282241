import s from './styles.module.scss'
import faq from './faq'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Main() {
  const questions = faq
  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const [selectedQuestionClass, setSelectedQuestionClass] = useState(0)

  return (
    <div className={s.faqSection}>
      <div>Frequently Asked Questions</div>
      <div>
        {questions.map(({ question, answer, links = [] }, i) => (
          <div
            className={
              i === selectedQuestion ? (selectedQuestionClass === 1 ? `${s.faq} ${s.activeFAQ}` : s.faq) : s.faq
            }
            onClick={event => {
              setSelectedQuestion(i)
              setSelectedQuestionClass(event.currentTarget.classList.length)
            }}
            key={i}
          >
            <div className={s.question}>
              {question}
              <span className='material-icons-outlined'>
                {i === selectedQuestion ? (selectedQuestionClass === 1 ? 'remove' : 'add') : 'add'}
              </span>
            </div>
            <div className={s.answer}>
              {answer}
              <div className={s.links}>
                {links.map(({ label, link }, i) => (
                  <Link key={i} onClick={() => window.scroll(0, 0)} to={link}>
                    {label}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
