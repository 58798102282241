import axios from 'axios'

const API_HOST = process.env.REACT_APP_API_HOST

const handler = ({ baseURL = API_HOST, url = null, method = 'get', args = {} }) => {
  const request = {}

  request.url = url
  request.baseURL = baseURL
  request.method = method.toLowerCase()

  if (request.method === 'get') request.params = args
  else request.data = args

  return axios(request).then(
    response => {
      return response.data
    },
    async error => (error?.response ? error.response.data : {})
  )
}

export default handler
