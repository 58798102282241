import images from 'images'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main}>
      <div className={s.left}>
        <img className={s.bg} src={images.clouds} alt='' />
        <img className={s.triangle} src={images.abstractTriangle} alt='' />

        <div className={s.landingInfo}>
          <h1 className={s.title}>
            Simplest
            <br />
            Server-Side Rendering
            <br />
            Implementation
          </h1>

          <p className={s.info}>
            Get your single page application indexed on all the major search engines with social sharing and advanced
            caching.
          </p>
        </div>

        <div className={s.buttons}>
          <Link to='/docs/'>
            <div className={s.docs}>
              Docs<span className='material-icons-outlined'>east</span>
            </div>
          </Link>

          {/* <Link to='/docs/'>
            <div className={s.youtube}>
              <span className='material-icons'>play_arrow</span>
              &nbsp;in 2 mins
            </div>
          </Link> */}
        </div>
      </div>

      <div className={s.right}>
        <img className={s.bulb} src={images.Bulbs} alt='' />
        <img className={s.ellipse} src={images.Ellipse} alt='' />
      </div>
    </div>
  )
}
