import images from 'images'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main}>
      <div className={s.container}>
        <div className={s.leftImage}>
          <img src={images.csr} alt='' />
        </div>

        <div className={s.content}>
          <h2 className={s.title}>Client-side rendering</h2>

          <p className={s.info}>
            When a web page is sent with all the logic and the browser dynamically generates the content using
            JavaScript is called <b>client-side rendering</b>.
            <br />
            <br />
            But this is not the best approach because crawlers/bots do not execute JavaScript to render pages and your
            <b> website pages will not get indexed on the web</b>.
            <br />
            <br />
            All SPA technologies like <b>ReactJS, AngularJS</b> etc uses client-side rendering.
          </p>
        </div>
      </div>

      <div className={s.container}>
        <div className={s.content}>
          <h2 className={s.title}>Server-side rendering</h2>

          <p className={s.renderingInfo}>
            When a web page is sent with all the html and the browser can directly render the content is called
            <b> server-side rendering</b>.
            <br />
            <br />
            Server-side rendering is good but <b>process intensive</b>, as every single page is rendered on the server.
            It's good because <b>crawlers/bots</b> can read page content & index your page.
            <br />
            <br />
            And implementing SSR on SPA apps is very <b>tricky</b> as they are meant to be rendered on the server.
          </p>
        </div>

        <div className={s.rightImage}>
          <img src={images.ssr} className={s.ssrimg} alt='' />
        </div>
      </div>

      <div className={s.container}>
        <div className={s.leftImage}>
          <img src={images.dr} alt='' />
        </div>

        <div className={s.content}>
          <h2 className={s.title}>Smart Rendering</h2>
          <p className={s.info}>
            Both CSR & SSR have their pros & cons. So the best thing that can be done is <b>Smart Rendering</b>.
            <br />
            <br />
            <b>The server can differentiate between human and robots & use SSR & CSR as required</b>, giving us the full
            experience and serves robot with a lightweight HTML version required only for indexing the page on the web.
          </p>
        </div>
      </div>
    </div>
  )
}
