import { useEffect, useState } from 'react'
import s from './styles.module.scss'
import { FAQ, HeadlessClients } from 'components'
import head from 'helpers/head'
import * as utils from 'helpers/utils'

export default function Main() {
  useEffect(() => {
    head({ title: 'Support | Headless SPAS' })
  }, [])

  return (
    <div className={s.main}>
      <GetInTouch />
      <FAQ />
      <HeadlessClients />
    </div>
  )
}

const GetInTouch = () => {
  const urlParams = JSON.parse(window.atob(window.location.search.slice('1') || 'e30='))

  const [name, setName] = useState(urlParams.name || '')
  const [subject, setSubject] = useState(urlParams.subject || '')
  const [message, setMessage] = useState(urlParams.message || '')
  const [contact, setContact] = useState(urlParams.contact || '')

  const submitQuery = async e => {
    e.preventDefault()
  }

  return (
    <div className={s.section2}>
      <div className={s.supportFormHeading}>
        <div>Talk to a Headless expert</div>
        <div>The easiest way to Server-side render your page with Headless by Joahquin.</div>
      </div>
      <div className={s.supportFormBody}>
        <div className={s.backgroundIllustration}></div>
        <div className={s.form} onSubmit={submitQuery}>
          <div className={s.left}>
            <div className={s.formHeading}>We generally replies within 24 hrs</div>
            {/* <div className={s.formVideo}>
            <iframe
              src='https://www.youtube.com/embed/K4DyBUG242c?list=RDK4DyBUG242c'
              title='Cartoon - On & On (feat. Daniel Levi) [NCS Release]'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div> */}
          </div>
          <div className={s.right}>
            <div>
              <div>Get in touch</div>

              <form className={s.formSubmit} {...utils.form}>
                <div className={s.formField}>
                  <input
                    placeholder='Enter your name..'
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                  <label className={s.fieldLabel}>Name</label>
                </div>
                <div className={s.formField}>
                  <input placeholder='What is it about?' value={subject} onChange={e => setSubject(e.target.value)} />
                  <label className={s.fieldLabel}>Subject</label>
                </div>
                <div className={s.formField}>
                  <label>Message</label>
                  <textarea
                    placeholder='Enter you message here..'
                    rows='3'
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  />
                </div>
                <div className={s.formField}>
                  <input placeholder='Mobile/Email' value={contact} onChange={e => setContact(e.target.value)} />
                  <label className={s.fieldLabel}>Contact</label>
                </div>
                <button type='submit'>Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
