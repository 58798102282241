import images from 'images'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main}>
      <img className={s.topLeftImage} src={images.vectorIllus} alt='' />
      <img className={s.squares} src={images.squareIllus} alt='' />

      <h1 className={s.cardHeading}>Supported Frameworks</h1>

      <div className={s.cardsWrapper}>
        {cards.map(({ label, image, color }, i) => (
          <Card key={i} label={label} image={image} labelColor={color} />
        ))}
      </div>
    </div>
  )
}

const Card = ({ image, labelColor, label }) => (
  <div className={s.cardWrapper}>
    <Link to='/blog/spa-with-headless/'>
      <div className={s.card}>
        <div className={s.image}>
          <img src={image} alt='' />
        </div>

        <div
          className={s.label}
          style={{
            color: labelColor
          }}
        >
          {label}
        </div>
      </div>
    </Link>
  </div>
)

const cards = [
  {
    label: 'ReactJS',
    image: images.react,
    color: '#00BCD4'
  },
  {
    label: 'VueJS',
    image: images.vue,
    color: '#41B883'
  },
  {
    label: 'AngularJS',
    image: images.angular,
    color: '#DD0330'
  },
  {
    label: 'BackboneJS',
    image: images.backBone,
    color: '#0071B5'
  },
  {
    label: 'EmberJS',
    image: images.ember,
    color: '#E14A30'
  }
]
