import { useEffect } from 'react'

import { CheckSSR, HeadlessClients, FAQ } from 'components'
import Intro from './intro'
import Frameworks from './frameworks'
import HeadlessSPAS from './headlessSPAS'
import WhyHeadless from './whyHeadless'
import ImageAndInfo from './imageAndInfo'
import head from 'helpers/head'

export default function Main() {
  useEffect(() => {
    head({})
  }, [])

  return (
    <>
      <Intro />
      <CheckSSR />
      <HeadlessSPAS />
      <ImageAndInfo />
      <WhyHeadless />
      <Frameworks />
      <HeadlessClients />
      <FAQ />
    </>
  )
}
