import { Link } from 'react-router-dom'
import logo from './logo.png'
import s from './styles.module.scss'

export default function Main({ fullscreen, setFullscreen }) {
  return (
    <div id='nav' className={s.outernav}>
      <nav className={s.nav}>
        <div className={s.left}>
          <Link to='/home'>
            <img src={logo} alt='' />
          </Link>
        </div>

        <div className={s.right}>
          <NavLinks />
        </div>

        <div className={s.hamburger + ' material-icons'} onClick={() => setFullscreen(true)}>
          menu
        </div>
      </nav>

      {!!fullscreen && (
        <div className={s.fullscreenMenu} onClick={() => setFullscreen(false)}>
          <div className={s.close + ' material-icons'}>close</div>

          <NavLinks />
        </div>
      )}
    </div>
  )
}

const NavLinks = () => (
  <div className={s.navLinks}>
    {navLinks.map(({ name, path }, i) => (
      <Link key={i} to={path} className={window.location.pathname.startsWith(path) ? s.active : ''}>
        <div>{name}</div>
      </Link>
    ))}
  </div>
)

const navLinks = [
  {
    name: 'Home',
    path: '/home'
  },
  {
    name: 'Docs',
    path: '/docs'
  },
  {
    name: 'Pricing',
    path: '/pricing'
  },
  {
    name: 'Blogs',
    path: '/blogs'
  },
  {
    name: 'Support',
    path: '/support'
  }
]
