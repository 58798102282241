import images from 'images'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.section5}>
      <div>
        <p>Why Headless?</p>
      </div>

      <div className={s.cards}>
        <div>
          <Card
            label={'Clean'}
            labelText={
              'Today, there is no simple way to setup server side rendering without making changes in your source code.'
            }
            image={images.iconClean}
            cardBack={images.cardBack2}
            cardBackGrad={'linear-gradient(0deg, rgba(27, 94, 42, 0.73), rgba(27, 94, 42, 0.73))'}
          />
          <Card
            label={'Simple'}
            labelText={'Once setup is done, Headless SPAS itself read & update changes.'}
            image={images.iconSimple}
            cardBack={images.cardBack3}
            cardBackGrad={'linear-gradient(0deg, rgba(163, 131, 255, 0.25), rgba(163, 131, 255, 0.25))'}
          />
          <Card
            label={'Fast'}
            labelText={
              'Headless SPAS makes your application load time lighting fast using self-managed client-side caching.'
            }
            image={images.iconFast}
            cardBack={images.cardBack1}
            cardBackGrad={'linear-gradient(0deg, rgba(100, 13, 13, 0.73), rgba(100, 13, 13, 0.73))'}
          />
        </div>

        <div>
          <Card
            label={'Smart'}
            labelText={
              'Headless SPAS uses smart SSR which automatically detects when to render pages on server, making it highly efficient.'
            }
            image={images.iconSmart}
            cardBack={images.cardBack4}
            cardBackGrad={'linear-gradient(0deg, rgba(166, 50, 50, 0.74), rgba(166, 50, 50, 0.74))'}
          />

          <Card
            label={'Scalable'}
            labelText={'Headless SPAS is a docker based tool which can be scaled as required.'}
            image={images.iconScalable}
            cardBack={images.cardBack5}
            cardBackGrad={'linear-gradient(0deg, rgba(250, 141, 42, 0.74), rgba(250, 141, 42, 0.74))'}
          />
        </div>
        <img src={images.squareIllus} alt='' />
      </div>
    </div>
  )
}

const Card = props => (
  <div className={s.featureCards}>
    <div
      className={s.card}
      style={{
        backgroundImage: `${props.cardBackGrad},url(${props.cardBack})`
      }}
    >
      <div className={s.featureCardFront}>
        <div>
          <img src={props.image} alt='' />
        </div>
        <div>
          <p>{props.label}</p>
        </div>
        <div>
          <p>{props.labelText}</p>
        </div>
      </div>
      <div className={s.featureCardBack}>
        <div>
          <p>{props.label}</p>
        </div>
        <div>
          <p>{props.labelText}</p>
        </div>
        <div>
          <a href='/docs'>Learn More</a>
        </div>
      </div>
    </div>
  </div>
)
