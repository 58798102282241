import { useCallback, useEffect, useState } from 'react'
import s from './styles.module.scss'
import { Link } from 'react-router-dom'
import * as api from 'api'
import { Loader } from 'components'
import head from 'helpers/head'

export default function Main() {
  const [loading, setLoading] = useState(true)
  const [blogList, setBlogList] = useState([])

  useEffect(() => {
    head({ title: 'Blogs | Headless SPAS' })
  }, [])

  const fetchBlogIndex = useCallback(async () => {
    try {
      const blogAPI = await api.blog.list()
      setBlogList(blogAPI)
      setLoading(false)
    } catch (error) {
      console.error(error)
      return
    }
  }, [])

  useEffect(() => {
    fetchBlogIndex()
  }, [fetchBlogIndex])

  return loading ? (
    <Loader />
  ) : (
    <div className={s.main}>
      <div className={s.blogHeading}>
        <h1>Headless SPAS Blogs</h1>
        <p>The hot topics about tech around the globe</p>
      </div>

      <div className={s.blogs}>
        {blogList.map(({ title, description, path }, i) => (
          <div className={s.blogWrapper} key={i}>
            <Link to={'/blog' + path}>
              <div className={s.blog} key={i}>
                <div className={s.blogImage}>
                  <img src={'/blog' + path + '/main.jpg'} alt='' />
                </div>

                <div className={s.blogInfo}>
                  <div className={s.title}>{title}</div>
                  <div className={s.description}>{description}</div>
                  <div className={s.link}>
                    Read More <span className='material-icons-round'>arrow_right_alt</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
