import logo from './home/logoVector/logo.png'

import Bulbs from './home/section1Images/Bulbs.svg'
import clouds from './home/section1Images/clouds.svg'

import ssrIllustration from './home/checkSSR/ssrIllus.png'

import react from './home/frameworkImages/React.jpg'
import ember from './home/frameworkImages/Ember.jpg'
import vue from './home/frameworkImages/Vue.jpg'
import backBone from './home/frameworkImages/Backbone.jpg'
import angular from './home/frameworkImages/Angular.jpg'

import abstractTriangle from './home/abstractIllustrations/abstractTriangle.svg'
import rectIllus from './home/abstractIllustrations/rectangleillus.png'
import Ellipse from './home/abstractIllustrations/Ellipse.svg'
import vectorIllus from './home/abstractIllustrations/vectorillus.png'
import vectorIllus2 from './home/abstractIllustrations/vectorillus2.png'
import vectorIllus2Light from './home/abstractIllustrations/vectorillus2light.png'
import squareIllus from './home/abstractIllustrations/squareillus.png'

import cardBack1 from './home/cards/card1.png'
import cardBack2 from './home/cards/card2.png'
import cardBack3 from './home/cards/card3.png'
import cardBack4 from './home/cards/card4.png'
import cardBack5 from './home/cards/card5.png'

import iconFast from './home/cards/iconfast.png'
import iconClean from './home/cards/iconclean.png'
import iconSimple from './home/cards/iconsimple.png'
import iconSmart from './home/cards/iconsmart.png'
import iconScalable from './home/cards/iconscalable.png'

import csr from './home/renderingInfo/csr.jpg'
import ssr from './home/renderingInfo/ssr.jpg'
import dr from './home/renderingInfo/dr.jpg'

import clients from './home/headlessClients/clients.png'

import docsImage from './docs/docsSection1/docsImage.png'

import domainCheck from './pricing/domainCheck.png'

const main = {
  logo,
  Bulbs,
  clouds,
  ssrIllustration,
  react,
  ember,
  vue,
  backBone,
  angular,
  abstractTriangle,
  rectIllus,
  Ellipse,
  vectorIllus,
  vectorIllus2,
  vectorIllus2Light,
  squareIllus,
  cardBack1,
  cardBack2,
  cardBack3,
  cardBack4,
  cardBack5,
  iconFast,
  iconClean,
  iconSimple,
  iconSmart,
  iconScalable,
  csr,
  ssr,
  dr,
  clients,
  docsImage,
  domainCheck
}

export default main
