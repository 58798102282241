import handler from 'helpers/apiHandler'

const prefix = '/blog'

const methods = {
  list: args => handler({ baseURL: window.location.origin, method: 'get', url: prefix + '/index.json', args }),
  content: (args, { path }) =>
    handler({ baseURL: window.location.origin, method: 'get', url: prefix + `/${path}/content.md`, args })
}

export default methods
