import { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'

import routes from './routes'

export default function main() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Redirect />} />

        {routes.map(({ path, Component, Super }, i) => (
          <Route
            key={i}
            path={path}
            element={
              Super ? (
                <Super>
                  <Component />
                </Super>
              ) : (
                <Component />
              )
            }
          />
        ))}

        <Route path='*' element={<Redirect />} />
      </Routes>
    </Router>
  )
}

const Redirect = () => {
  const navigate = useNavigate()
  useEffect(() => navigate('/home', { replace: true }), [navigate])
  return null
}
