import images from 'images'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main}>
      <img className={s.topRightImage} src={images.vectorIllus} alt='' />
      <img className={s.squares} src={images.squareIllus} alt='' />

      <div className={s.title}>
        <div>
          <img src={images.logo} alt='' />
        </div>
        <h1>What is Headless SPAS?</h1>
      </div>
      <div className={s.info}>
        <p>
          A dedicated scalable single-page application server (SPAS) <br /> with integrated{' '}
          <b>smart server-side rendering</b>
          <br />
          and
          <b> self-managed client-side caching</b>.
        </p>
      </div>
    </div>
  )
}
