import s from './styles.module.scss'
import { useCallback, useEffect, useState } from 'react'
import * as api from 'api'
import { Loader } from 'components'
import mdHandler from 'helpers/mdHandler'

export default function Main() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const selectBlog = useCallback(async path => {
    var md = await api.blog.content({}, { path })

    setLoading(false)

    const { html } = mdHandler({
      md,
      titleSuffix: ' | Blog | Headless SPAS',
      img: `/blog/${path}/main.jpg`
    })

    document.querySelector('#content1').innerHTML = html
    window?.Prism.highlightAll()
  }, [])

  useEffect(() => {
    const location = window.location.pathname
    selectBlog(
      location.charAt(location.length - 1) === '/' ? location.substring(6, location.length - 1) : location.substring(6)
    )
    document.title =
      (location.charAt(location.length - 1) === '/'
        ? location.substring(6, location.length - 1)
        : location.substring(6)) + ' | Headless SPAS'
  }, [selectBlog])

  return (
    <div className={s.main}>
      {!!loading && <Loader />}

      <img className={s.img} src={window.location.pathname + '/main.jpg'} alt='' />

      <div id='content1' className='markdown'></div>
    </div>
  )
}
