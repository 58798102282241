import images from 'images'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main}>
      <div
        style={{
          background: `url(${images.vectorIllus2Light}), linear-gradient(180deg, #91D4E9 0%, #00789E 100%)`,
          backgroundSize: '28rem auto'
        }}
        className={s.mainFooter}
      >
        <div>
          <div className={s.footerHeading}>24x7 Support</div>

          <Link to='/support'>
            <div className={s.footerLink}>Need Help ?</div>
          </Link>

          <div className={s.footerInfo}>Simplest & fastest way to implement server-side rendering</div>
        </div>
      </div>

      <div className={s.copyrightFooter}>
        <div>© 2019-22</div>
        <div>
          <a href='https://www.joahquin.com' target='_blank' rel='noreferrer'>
            <span style={{ color: 'var(--c-font)' }}>Powered by </span>Joahquin Technologies
          </a>
        </div>
        <div>
          <a href='https://www.joahquin.com/static/terms-of-service' target='_blank' rel='noreferrer'>
            Terms & Conditions
          </a>
        </div>
        <div>
          <a href='https://www.joahquin.com/static/privacy-policy' target='_blank' rel='noreferrer'>
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  )
}
