import React from 'react'
import ReactDOM from 'react-dom/client'
import Pages from './pages'
import './styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Pages />
  </React.StrictMode>
)

document.querySelector("link[rel='canonical']").href = process.env.PUBLIC_URL + window.location.pathname
