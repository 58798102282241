import images from 'images'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main}>
      <div className={s.left}>
        <div className={s.heading}>Who is using Headless?</div>
        <div className={s.subHeading}>
          Headless SPAS is the most reliable solution for implementing server-side rendering.
          <br />
          <br />
          We make it easier for you to focus on building great products without worrying about how crawlers and bots
          interprets you website.
        </div>

        <br />

        <p style={{ color: 'var(--c-font-dark)', fontWeight: 700 }}>
          More than <span style={{ fontSize: '2rem' }}>100</span> companies rely on Headless SPAS.
        </p>
      </div>
      <div className={s.right}>
        <div className={s.clientColumn1} style={{ backgroundImage: `url(${images.clients})` }}></div>
        <div className={s.clientColumn2} style={{ backgroundImage: `url(${images.clients})` }}></div>
      </div>
      <img src={images.vectorIllus} alt='' />
    </div>
  )
}
