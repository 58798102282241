import images from 'images'
import { useState } from 'react'
import * as api from 'api'
import { Loader } from 'components'
import s from './styles.module.scss'

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [website, setWebsite] = useState('')
  var [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [error, setError] = useState('')
  const [tab, setTab] = useState('preview')

  const loadWebsite = async e => {
    try {
      e.preventDefault()

      setTimeout(() => setError(''), 2000)

      if (!website) return setError('Website/URL required!')
      if (website.includes('hless.pro')) return setError('Testing tool can not test itself!')

      setLoading(true)
      setContent('')
      setTitle('')

      var url = website
      if (!url.startsWith('http://') && !url.startsWith('https://')) url = 'http://' + url
      const htmlResponse = await api.ssr.test({ url })

      setLoading(false)
      const _content = htmlResponse.payload.html || 'Page not found!'

      setContent(formatHTML(_content))

      try {
        const _title = /<title>(.*?)<\/title>/gm.exec(_content)[1]
        setTitle(_title, 'here')
        console.log(title)
      } catch (error) {}
    } catch (error) {
      console.error(error)
      setLoading(false)
      return
    }
  }

  return (
    <div>
      <div className={s.ssr}>
        <div className={s.left}>
          <img src={images.ssrIllustration} alt='' />
        </div>

        <div className={s.right}>
          <div className={s.title}>
            Check
            <br />
            Server-Side
            <br />
            Rendering
            <br /> On Your Website
          </div>

          <div>
            <form onSubmit={loadWebsite}>
              <input
                type='text'
                value={website}
                onChange={e => setWebsite(e.target.value)}
                placeholder='Enter your website/page url..'
              />

              {!!error && <div className={s.alert}>{error}</div>}

              <button onClick={loadWebsite}>Go</button>
            </form>
          </div>
        </div>
      </div>

      <div className={s.result}>
        {loading && <Loader />}

        {!!content && (
          <div>
            <div className={s.info}>This is how crawler/bots see your page to index the content</div>

            <div className={s.browser}>
              <div className={s.header}>
                <div className={s.title}>{title}</div>
                <div className={s.buttons}>
                  <div></div>
                  <div></div>
                  <div style={{ cursor: 'pointer' }} onClick={() => setContent('')}></div>
                </div>
              </div>

              {tab === 'preview' && (
                <iframe
                  srcDoc={content}
                  title='ssr-on-your-website'
                  sandbox='allow-forms allow-scripts allow-same-origin'
                />
              )}

              {tab === 'code' && (
                <pre className={s.code}>
                  <code id='ssr-code'>{content}</code>
                </pre>
              )}
            </div>

            <div className={s.tabs}>
              <div className={tab === 'preview' && s.active} onClick={() => setTab('preview')}>
                Preview
              </div>
              <div className={tab === 'code' && s.active} onClick={() => setTab('code')}>
                Code
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function formatHTML(html) {
  var tab = '  '
  var result = ''
  var indent = ''

  html.split(/>\s*</).forEach(function (element) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length)
    }

    result += indent + '<' + element + '>\r\n'

    // eslint-disable-next-line
    if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
      indent += tab
    }
  })

  return result.substring(1, result.length - 3)
}
