export default function Main({
  title = 'Headless SPAS | A dedicated single page application server',
  description = 'A dedicated scalable single-page application server (SPAS) with integrated smart server-side rendering and self-managed client-side caching.',
  image = '/share.jpg',
  largeImage = true
}) {
  const url = window.location.origin + window.location.pathname

  image = window.location.origin + image

  document.querySelector('title').innerText = title

  document.querySelector('meta[name="title"]').content = title
  document.querySelector('meta[name="description"]').content = description

  document.querySelector('meta[property="og:title"]').content = title
  document.querySelector('meta[property="og:description"]').content = description
  document.querySelector('meta[property="og:image"]').content = image
  document.querySelector('meta[property="og:url"]').content = url

  document.querySelector('meta[property="twitter:title"]').content = title
  document.querySelector('meta[property="twitter:description"]').content = description
  document.querySelector('meta[property="twitter:image:src"]').content = image
  document.querySelector('meta[property="twitter:url"]').content = url

  if (largeImage) document.querySelector('meta[property="twitter:card"]').content = 'summary_large_image'
  else document.querySelector('meta[property="twitter:card"]').content = 'summary'

  window.scrollTo(0, 0)
}
