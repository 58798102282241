import images from 'images'
import s from './styles.module.scss'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import head from 'helpers/head'
import * as api from 'api'

export default function Main() {
  useEffect(() => {
    head({ title: 'Pricing | Headless SPAS' })
  }, [])

  return (
    <div className={s.main}>
      <Pricing />
      <div id='next'></div>
      <DomainStatus />
    </div>
  )
}

const Pricing = () => (
  <div className={s.pricing}>
    <h1 className={s.title}>Let Headless Handle Your Website's Rendering</h1>

    <div className={s.cards}>
      <PriceCards
        heading={'Free'}
        subHead={'Free for personal / freelancer / open-source / NGO / non-commercial usage'}
        currency={'INR'}
        price={'0'}
        button={'Request'}
        info={['Unlimited subdomains', 'Unlimited page renders', 'Query reply within 72 hours']}
      />
      <PriceCards
        heading={'Paid'}
        subHead=<span>
          <b style={{ color: 'var(--c-font-dark)', fontSize: '1rem' }}>One year licence</b> for commercial usage
        </span>
        currency={'INR'}
        price={'5,999'}
        button={'Get in touch'}
        info={['Initial setup consultation', 'Lifetime free updates', 'Query reply within 24 hours']}
      />
      <PriceCards
        heading={'Earn'}
        subHead={'Refer & earn, become partner'}
        affilate={'Know More'}
        buttonSupport={'Contact Us'}
        info={['Technical training', 'Sales & marketing training', 'Query reply within 24 hours']}
      />
    </div>

    <div className={s.circles}>
      <div className={s.backCircle1}></div>
      <div className={s.backCircle2}></div>
      <div className={s.backCircle3}></div>
    </div>
  </div>
)

const PriceCards = props => (
  <div className={s.priceCards}>
    <div className={s.priceCardHeading}>
      <p>{props.heading}</p>
    </div>
    <div className={s.priceCardType}>
      <p>{props.subHead}</p>
    </div>
    <div className={s.priceCardCharges}>
      {props.affilate && (
        <div>
          <Link to={'/blog/earn'}>{props.affilate}</Link>
        </div>
      )}

      {!props.affilate && (
        <div className={s.price}>
          <p>{props.currency}</p>
          <p>{props.price}</p>
        </div>
      )}
    </div>

    <div className={s.priceCardButton}>
      {!props.buttonSupport && (
        <button onClick={() => document.querySelector('#next').scrollIntoView()}>{props.button}</button>
      )}
      {props.buttonSupport && (
        <button>
          <div onClick={() => supportRedirect('Partnership request')}>{props.buttonSupport}</div>
        </button>
      )}
    </div>

    <div className={s.priceCardFeatures}>
      {props.info.map((text, i) => (
        <div key={i} className={s.cardData}>
          <p>{text}</p>
        </div>
      ))}
    </div>
    {/* <div className={s.priceCardSupport}>
      <Link to={'/support'}>Help?</Link>
    </div> */}
  </div>
)

const DomainStatus = () => {
  const [website, setWebsite] = useState('')
  const [domain, setDomain] = useState('')
  const [status, setStatus] = useState('')

  const checkDomain = async e => {
    e.preventDefault()

    setDomain('')
    setStatus('')

    if (!website) return setStatus('domainRequired')
    var _domain = website

    if (!_domain.startsWith('http')) _domain = 'http://' + _domain

    _domain = new URL(_domain)

    _domain = _domain.host

    try {
      const domainStatusAPI = await api.domain.status({ domain: _domain })
      if (domainStatusAPI.code !== 'OK') throw new Error()
      setStatus(domainStatusAPI.payload.status)
    } catch (error) {
      setStatus('invalid')
    }

    setDomain(_domain)
  }

  const raiseQuery = async (type = 'paid') => {
    const subject = `Request ${type === 'paid' ? 'paid' : 'free'} access: ${domain}`
    supportRedirect(subject)
  }

  return (
    <div className={s.domainStatus}>
      <div className={s.title}>Let's check current status of your domain</div>

      <div className={s.info}>Here! Let Us Help You To Set Up Your Domain</div>

      <div className={s.domainCheckImg}>
        <img src={images.domainCheck} alt='' />
      </div>

      <form onSubmit={checkDomain}>
        <div className={s.domainSearch}>
          <div>
            <input
              value={website}
              onChange={e => setWebsite(e.target.value)}
              type='text'
              placeholder='Enter Your Domain e.g. www.example.com'
            />
          </div>

          <div>
            <button onClick={checkDomain}>Search Domain</button>
          </div>
        </div>
      </form>

      <div className={s.result}>
        <div className={s.website}>{domain}</div>
        {status === 'domainRequired' && (
          <div style={{ color: 'var(--c-red)' }} className={s.status}>
            Domain/Url Required
          </div>
        )}

        {status === 'invalid' && (
          <div style={{ color: 'var(--c-red)' }} className={s.status}>
            Invalid domain
          </div>
        )}

        {status === 'unregistered' && (
          <div style={{ color: 'var(--c-font-dark)' }} className={s.status}>
            Unregistered
          </div>
        )}

        {status === 'trial' && (
          <div>
            <div style={{ color: 'var(--c-orange)' }} className={s.status}>
              Trial
            </div>
            <div className={s.info}>After trial commercial usage is paid</div>
          </div>
        )}

        {status === 'free' && (
          <div>
            <div style={{ color: 'var(--c-green)' }} className={s.status}>
              Free
            </div>
            <div className={s.info}>Free for non-commercial usage</div>
          </div>
        )}

        {status === 'paid' && (
          <div>
            <div style={{ color: 'var(--c-green)' }} className={s.status}>
              Registered
            </div>
            <div className={s.info}>Paid for commercial usage</div>
          </div>
        )}

        {status === 'blocked' && (
          <div>
            <div style={{ color: 'var(--c-red)' }} className={s.status}>
              Blocked
            </div>
            <div className={s.info}>Domain blocked for any kind of usage</div>
          </div>
        )}

        {['unregistered', 'trial'].includes(status) && (
          <div className={s.buttons}>
            <button onClick={() => raiseQuery('paid')}>Buy now</button>
            <button className={s.free} onClick={() => raiseQuery('free')}>
              Request free access
            </button>
          </div>
        )}
      </div>

      <div className={s.illustrationLeft}>
        <img src={images.abstractTriangle} alt='' />
      </div>
      <div className={s.illustrationRight}>
        <img src={images.Ellipse} alt='' />
      </div>
    </div>
  )
}

const supportRedirect = subject =>
  (window.location.href =
    '/support?' +
    window.btoa(
      JSON.stringify({
        subject
      })
    ))
